import moment from "moment";

const fakeAppointmentsService = {
    dateFormat: "DD.MM.YYYY",
    timeFormat: " HH:mm",
    getAppointments: async function () {
        return Promise.resolve([
            {
                title: "Stonn OP un Danz im Scala Club Leverkusen",
                date: moment("02.11.2024 18:30", this.dateFormat + " " + this.timeFormat),
                canceled: false
            },
            {
                title: "Sommerfest auf dem Gelände der Steppenwölfe",
                date: moment("26.08.2023", this.dateFormat),
                canceled: false
            },
            {
                title: "Oktoberfest im Funkenkeller",
                date: moment("2.10.2023", this.dateFormat),
                canceled: false
            },
            {
                title: "Sessionseröffnung im Funkenkeller",
                date: moment("11.11.2023", this.dateFormat),
                canceled: false
            },
            {
                title: "MitSing Abend Im Stilbruch Opladen",
                date: moment("24.11.2023", this.dateFormat),
                canceled: false
            },
            {
                title: "Feldmesse in der St. Elisabeth Kirche",
                date: moment("20.01.2024", this.dateFormat),
                canceled: false
            },
            {
                title: "Kindersitzung im Scala Club Opladen",
                date: moment("21.01.2024", this.dateFormat),
                canceled: false
            },
            {
                title: "Abend unter Freunden in der Stadthalle Bergisch Neukirchen",
                date: moment("27.01.2024", this.dateFormat),
                canceled: false
            },
            {
                title: "Herrensitzung im Festzelt am Marktplatz Opladen",
                date: moment("04.02.2024", this.dateFormat),
                canceled: false
            },
            {
                title: "Weiberfastnacht im Funkenkeller",
                date: moment("08.02.2024", this.dateFormat + this.timeFormat),
                canceled: false
            },
            {
                title: "After Zoch Party im Funkenkeller",
                date: moment("12.02.2024", this.dateFormat),
                canceled: false
            }
        ])
    }
}

export default fakeAppointmentsService;