import React from "react";
import moment from "moment";
import fakeAppointmentsService from "../services/fakeAppointmentsService";

class AppointmentsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appointments: []
        };
    }

    async componentDidMount() {
        const appointments = await fakeAppointmentsService.getAppointments();

        if (appointments) this.setState({ appointments });
    }

    render() {
        const { appointments } = this.state;

        const filteredAppointments = appointments.filter(appointment => {
            return appointment.date.isSameOrAfter(moment());
        }).sort((a, b) => {
            return a.date.isSameOrBefore(b.date) ? -1 : 1;
        });

        if (filteredAppointments.length <= 0) {
            filteredAppointments.push({
                title: "Keine neuen Termine verfügbar..."
            })
        }

        return (
            <blockquote className="kgnsf-blockquote">
                <h2 className="kgnsf-header center">Termine</h2>
                <ul className="list-group">
                    {filteredAppointments.map((appointment, index) => {
                        return (
                            <li className="list-group-item" key={index}>
                                <h4 className={appointment.canceled ? "kgnsf-canceled" : ""}>
                                    {appointment.title}
                                </h4>
                                <p>
                                    {appointment.date && appointment.date.format(appointment.date._f + " ")}
                                    {appointment.canceled && <span className="badge bg-danger">Agesagt!</span>}
                                </p>
                            </li>
                        )
                    })}
                </ul>
            </blockquote>
        );
    }
}

export default AppointmentsComponent;