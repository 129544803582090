import react from "react";
import fakeProfilesService from "../services/fakeProfilesService";
import ContentImage from "./StateLessComponents/ContentImage";

class DirectorsComponent extends react.Component {
    constructor(props) {
        super(props);

        this.state = {
            directors: [],
            extendedDirectors: []
        };
    }

    getSorted = (src) => {
        return src.sort((a, b) => {
            return a.sort - b.sort || a.lastname.localeCompare(b.lastname)
        });
    }

    async componentDidMount() {
        const directors = await fakeProfilesService.getProfiles({
            filter: profile => {
                return profile.gfv === 'x'
            },
            orderBy: (a, b) => {
                return a.sort - b.sort || a.lastname.localeCompare(b.lastname)
            }
        });

        const extendedDirectors = await fakeProfilesService.getProfiles({
            filter: profile => {
                return profile.ev === 'x'
            },
            orderBy: (a, b) => {
                return a.sort - b.sort || a.lastname.localeCompare(b.lastname)
            }
        });

        this.setState({ directors, extendedDirectors });
    }

    render() {
        const { directors, extendedDirectors } = this.state;

        const template = function (d, i) {
            return (
                <div key={i} className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
                    <img alt="" className="img-fluid kgnsf-img" src={d.picture} />
                    <p className="kgnsf-img-title">
                        {d.rank}
                        <br />
                        {d.name}
                    </p>
                </div>
            )
        }

        return (
            <div>
                <h1 className={"kgnsf-header"}>Unser Vorstand</h1>
                <ContentImage image={"NSF Fototermin 2023/Gruppenbilder/Vorstand.jpg"} label={""} />

                <h1 className={"kgnsf-header"}>Die Geschäftsführenden Vorstandsmitglieder</h1>
                <div className="row">
                    {directors && directors.map((d, i) => {
                        return template(d, i)
                    })}
                </div>

                <h1 className={"kgnsf-header"}>Die erweiterterten Vorstandsmitglieder</h1>
                <div className="row">
                    {extendedDirectors && extendedDirectors.map((d, i) => {
                        return template(d, i)
                    })}
                </div>
            </div>
        );
    };
}

export default DirectorsComponent;