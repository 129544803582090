import React from 'react';
import fakeProfilesService from '../services/fakeProfilesService';
import VisitcardComponent from './VisitcardComponent';

class CellarComponent extends React.Component {
    constructor(props) {
        super(props);

        const path = "/images/neustadtfunken/keller";

        this.state = {
            kuehlhaus: { name: "Kühlhaus", path: path + "/keller3.jpg" },

            row1: [
                { name: "Sitzbereich", path: path + "/Bild 11.jpg" },
                { name: "Theke", path: path + "/Bild 12.jpg" },
                { name: "Steh- / Tanzfläche", path: path + "/Bild 15.jpg" },
                { name: "Flur", path: path + "/Bild 18.jpg" },
                { name: "Küche", path: path + "/Bild 19.jpg" },
            ],

            row2: [
                { name: "Gesamtraum", path: path + "/keller1.jpg" },
                { name: "Leinwand und Beamer", path: path + "/keller2.jpg" },
                { name: "Weiterer Raum", path: path + "/keller4.jpg" },
                { name: "Eingangsbereich", path: path + "/keller5.jpg" },
            ],

            rent: []
        }
    }

    async componentDidMount() {
        const rent = await fakeProfilesService.getProfiles({ filter: p => { return p.email === "kellervermietung@kgneustadtfunken.de" } });

        this.setState({ rent });
    }

    render() {
        const { kuehlhaus, row1, row2, rent } = this.state;

        return (
            <div>
                <h1 className='kgnsf-header'>Unser Funkenkeller</h1>

                <blockquote className='kgnsf-blockquote'>
                    <p>Covid-19 lehrte uns auf Abstand zu leben, mit Einschränkungen umzugehen und auf vieles zu verzichten. Doch das Virus, welches die Welt lahm legt, hatte auch etwas Gutes: Unser 1.Vorsitzender Kevin Schrauzer und unser Schatzmeister Dietmar Beck hatten unerwartet viel Zeit und Energie.</p>
                    <p>Dank den beiden hat der Funkenkeller nun einen weiteren Raum zum Feiern, verfügt nun über einen Beamer und Leinwand, bringt jede Party zum Kochen mit einer Lasermaschine und Nebelmaschine, besitzt endlich W-Lan und hat ein nagelneues Kühlhaus! Zudem ist ein Defibrillator im Funkenkeller eingezogen, eine Anschaffung die hoffentlich umsonst bleiben wird.</p>
                    <p>Suchen Sie vielleicht einen Ort für Ihre nächste Feier? Vielleicht steht bei Ihnen eine Taufe, Geburtstag oder sogar ein Seminar an und Sie sind auf der Suche nach der passenden Räumlichkeit? Melden Sie sich bei uns per Mail oder per Telefon.</p>
                </blockquote>

                <div className='row'>
                    {row1?.map((img, index) => {
                        return (
                            <div key={img.key + index} className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                                <img alt="" className='img-fluid kgnsf-img' src={img.path} />
                                <p className='kgnsf-img-title'>{img.name}</p>
                            </div>
                        )
                    })}
                </div>

                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <img alt="" className='img-fluid kgnsf-img' src={kuehlhaus.path} />
                        <p className='kgnsf-img-title'>{kuehlhaus.name}</p>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        {row2?.map(img => {
                            return (
                                <div key={img.key} className='col-12'>
                                    <img alt="" className='img-fluid kgnsf-img' src={img.path} />
                                    <p className='kgnsf-img-title'>{img.name}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className='row justify-content-end'>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        {rent && <VisitcardComponent title="Bei Fragen / Interesse" profiles={rent}></VisitcardComponent>}
                    </div>
                </div>
            </div>
        )
    }
}

export default CellarComponent;