import React from "react";
import fakeContentService from "../services/fakeContentService";
import fakeProfilesService from "../services/fakeProfilesService";
import ContentImageBlockquote from "./StateLessComponents/ContentImageBlockquote";
import VisitcardComponent from "./VisitcardComponent";

class PairComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            maries: [],
            officiers: [],
            trainers: []
        };
    }

    async componentDidMount() {
        const maries = await fakeProfilesService.getProfiles({ filter: p => { return p.marie === "x" }, orderBy: (a, b) => a.lastname.localeCompare(b.lastname) });
        const officiers = await fakeProfilesService.getProfiles({ filter: p => { return p.officier === "x" }, orderBy: (a, b) => a.lastname.localeCompare(b.lastname) });
        const trainers = await fakeProfilesService.getProfiles({ filter: p => { return p.trTP === "x" } });
        const content = await fakeContentService.getPairContent();

        maries?.forEach(profile => {
            profile.phone = null;
            profile.email = null;
        });

        officiers?.forEach(profile => {
            profile.phone = null;
            profile.email = null;
        });

        trainers?.forEach(profile => {
            profile.phone = null;
            profile.email = null;
        });

        this.setState({
            maries,
            officiers,
            trainers,
            content
        })
    }

    render() {
        const { maries, officiers, trainers, content } = this.state;

        return (
            <div>
                <h1 className="kgnsf-header">Unser Tanzpaar</h1>

                <ContentImageBlockquote
                    imageSrc={"NSF Fototermin 2023/Gruppenbilder/Tanzpaar.jpg"}
                    imageLabel={"Unser Tanzpaar 2023 / 2024, " + maries[0]?.name + " und " + officiers[0]?.name}
                    content={content}
                />

                <div className="row">
                    <div className="col">
                        {maries && <VisitcardComponent title="Marie:" profiles={maries}></VisitcardComponent>}
                    </div>
                    <div className="col">
                        {officiers && <VisitcardComponent title="Tanzoffizier:" profiles={officiers}></VisitcardComponent>}
                    </div>
                    <div className="col">
                        {trainers && <VisitcardComponent title="Trainer:" profiles={trainers}></VisitcardComponent>}
                    </div>
                </div>
            </div >
        )
    }
}

export default PairComponent