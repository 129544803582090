import $ from "jquery";
import moment from "moment/moment";

const getProfiles = function () {
    const profiles = [];

    $.ajax({
        url: "profiles.csv?v=" + moment().toISOString(),
        async: false,
        method: "GET",
        success: function (response) {
            const rows = response.replaceAll("\n", "").split("\r");

            const properties = rows.shift().split(";");

            const data = rows.map(r => { return r.split(";") });

            data.forEach(d => {
                const profile = {};
                properties.forEach((p, i) => {
                    if (p === "sort") profile[p] = Number(d[i]);
                    else profile[p] = d[i];
                });
                profiles.push(profile);
            });
        }
    });

    return profiles;
};

const fakeProfilesService = {
    getProfiles: async (config) => {
        var data = getProfiles();

        config.filter && (data = data.filter(d => {
            return config.filter(d)
        }));

        config.orderBy && (data = data.sort((a, b) => {
            return config.orderBy(a, b)
        }));

        return Promise.resolve(data)
    }
};

export default fakeProfilesService;