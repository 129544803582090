import React from "react";
import fakeContentService from "../services/fakeContentService";
import fakeProfilesService from "../services/fakeProfilesService";
import ContentImage from "./StateLessComponents/ContentImage";
import ContentBlockquote from "./StateLessComponents/ContentBlockquote";
import VisitcardComponent from "./VisitcardComponent";

class PawnsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            trainersBigPawns: [],
            trainersLittlePawns: [],
            princePairCaretaker: [],
            bookingPartner: []
        };
    }

    async componentDidMount() {
        const trainersBigPawns = await fakeProfilesService.getProfiles({ filter: p => { return p.trGrP === "x" }, orderBy: (a, b) => a.lastname.localeCompare(b.lastname) });
        const trainersLittlePawns = await fakeProfilesService.getProfiles({ filter: p => { return p.trKlP === "x" }, orderBy: (a, b) => a.lastname.localeCompare(b.lastname) });
        const bookingPartner = await fakeProfilesService.getProfiles({ filter: p => { return p.bookP === "x" } });
        const contentPair = await fakeContentService.getPawnsPairContent();
        const content = await fakeContentService.getPawnsContent();

        trainersLittlePawns?.forEach(profile => {
            profile.phone = null;
            profile.email = null;
        });

        trainersBigPawns?.forEach(profile => {
            profile.phone = null;
            profile.email = null;
        });

        this.setState({
            trainersBigPawns,
            trainersLittlePawns,
            bookingPartner,
            contentPair,
            content
        })
    }

    render() {
        const { trainersBigPawns, trainersLittlePawns, bookingPartner, content } = this.state;

        return (
            <div>
                <h1 className="kgnsf-header">Unsere Pänz vom Rosenhügel</h1>

                <ContentImage image={"NSF Fototermin 2023/Gruppenbilder/Pänz.jpg"} label={"Unsere Pänz 2023 / 2024"} />

                <ContentBlockquote content={content} />

                <div className="row">
                    <div className="col">
                        {trainersLittlePawns && <VisitcardComponent title="Trainer*innen: kleine Pänz" profiles={trainersLittlePawns}></VisitcardComponent>}
                    </div>
                    <div className="col">
                        {trainersBigPawns && <VisitcardComponent title="Trainer*innen: große Pänz" profiles={trainersBigPawns}></VisitcardComponent>}
                    </div>
                    <div className="col">
                        {bookingPartner && <VisitcardComponent title="Bei Fragen / Buchung" profiles={bookingPartner}></VisitcardComponent>}
                    </div>
                </div>
            </div>
        )
    }
}

export default PawnsComponent