import { Route, Routes } from "react-router-dom";
import "bootstrap";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import './App.css';

import NavComponent from './components/NavComponent';
import HomeComponent from './components/HomeComponent';
import DirectorsComponent from "./components/DirectorsComponent";
import ImprintComponent from "./components/ImprintComponent";
import NotFoundComponent from './components/NotFoundComponent';
import FooterComponent from "./components/FooterComponent";
import CellarComponent from "./components/CellarComponent";
import HistoryComponent from "./components/HistoryComponent";
import PawnsComponent from "./components/PawnsComponent";
import GardeComponent from "./components/GardeComponent";
import CorpsComponent from "./components/CorpsComponente";
import PairComponent from "./components/PairComponent";
import PedastriansComponent from "./components/PedastriansComponent";
import PawnspairComponent from "./components/PawnspairComponent";

function App() {
  return (
    <div className={"bg-red"}>
      <NavComponent />
      <div className={"main container center bg-kgnsf"}>
        <Routes>
          <Route path="/home" element={<HomeComponent />} />
          <Route path="/pawnspair" element={<PawnspairComponent />} />
          <Route path="/pawns" element={<PawnsComponent />} />
          <Route path="/garde" element={<GardeComponent />} />
          <Route path="/corps" element={<CorpsComponent />} />
          <Route path="/pair" element={<PairComponent />} />
          <Route path="/pedastrians" element={<PedastriansComponent />} />
          <Route path="/directors" element={<DirectorsComponent />} />
          <Route path="/history" element={<HistoryComponent />} />
          <Route path="/cellar" element={<CellarComponent />} />
          <Route path="/imprint" element={<ImprintComponent />} />
          <Route path="/" element={<HomeComponent />} />
          <Route path="*" element={<NotFoundComponent />} />
        </Routes>
      </div>
      <FooterComponent />
    </div>
  );
}

export default App;
