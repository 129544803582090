import React from "react";
import ContentBlockquote from "./ContentBlockquote";
import ContentImage from "./ContentImage";

const ContentImageBlockquote = ({ imageSrc, imageLabel, content }) => {
    return (
        <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <ContentImage image={imageSrc} label={imageLabel} />
            </div>
            <div className="col-lg-8 col-md-8 col-sm-6 col-xs-12">
                <ContentBlockquote content={content} />
            </div>
        </div >
    );
};

export default ContentImageBlockquote;