import React from "react";
import NewsComponent from "./NewsComponent";
import AppointmentsComponent from "./AppointmentsComponent";

const HomeComponent = () => {
    return (
        <div>
            <h1 className={"kgnsf-header"}>Willkommen bei den Neustadtfunken</h1>

            <img alt="" className={"img-fluid kgnsf-img"} src={"NSF Fototermin 2023/Gruppenbilder/Alle 1.jpg"} />

            <div className={"row"}>
                <div className={"col-lg-8 col-md-8 col-sm-12 col-xs-12"}>
                    <NewsComponent />
                </div>
                <div className={"col-lg-4 col-md-4 col-sm-12 col-xs-12"}>
                    <AppointmentsComponent />
                </div>
            </div>
        </div>
    );
};

export default HomeComponent;