const fakeContentService = {
    getCorpsContent: async function () {
        return Promise.resolve([
            "Das Traditionscorps der KG Neustadtfunken versteht sich als ein traditionelles Offizierscorps, dass es sich zur Aufgabe gemacht hat, Frohsinn und Freude in die 5. Jahreszeit zu bringen. Sie pflegen das Brauchtum durch ihre historischen Uniformen, traditionellem Regelwerk (Beförderungen, Abzeichen, Dienstgrade) und ihrer Liebe und Hingabe zum Opladener Karneval.",
            "Im Jahr 1998 gründetet eine Gruppe gestandener Karnevalisten das Traditionschorps der Gesellschaft, da es ihnen eben nicht mehr ausreichte, den Fastelovend nur zu feiern, sie wollten aktiv an ihm teilnehmen. Lotti Bölker, eine ehemalige Marie der Neustadtfunken, konnte schnell zur 1. Trainerin rekrutiert werden. Zu Beginn waren die Ziele noch sehr bescheiden, lediglich „Stippeföttchen“ sollte auf den Brettern der Welt zum Besten gebracht werden. Doch Lotti erkannte schnell Potential in ihren Offizieren und so war schnell der beliebte Klassiker „Der Funkentanz“ geboren. In den folgenden Jahren lösten sich die Trainerinnen ab, von denen jede einzelne zur tänzerischen Weiterentwicklung beigetragen hat. Meike Mönchmeyer-Göddertz bringt nun seit mehreren Jahren bereits ihre Jungs ins Schwitzen und holt Ungeahntes aus ihnen heraus. Vor über 20 Jahren hätte noch niemand gedacht, dass das Traditionscorps der KG Neustadtfunken sich solche Schrittkombinationen in einem solchen Tempo merken oder so hoch die Beine werfen könnte. Seit mehreren Jahren nun tanzen die Männer nicht mehr alleine mit ihrer Marie, sondern heben auch die Tanzgarde in ungeahnte Höhen. Die gemeinsamen Tänze von Tanzpaar, Tanzgarde und Traditionscorps macht die KG Neustadtfunken einmalig.",
            "Neben dem Training trifft sich das Traditionscorps mehrmals jährlich zum Stammtisch, Oktoberfest, Funkentour und jedem nur denkbaren Event. Ganz nach dem Motto: 'Man(n) sollte die Feste feiern wie sie fallen.'"
        ]);
    },
    getGardeContent: async function () {
        return Promise.resolve([
            "„Wenn rut und wieß zesamme kütt, es dat die Formel för et Jlöck!“",
            " ",
            "Daher finden sich seit mehreren Jahrzehnten die Mädchen der Tanzgarde im Herzen der Opladener Neustadt zum wöchentlichen Training zusammen.",
            "Das Training wird mit großer Liebe und Hingabe von Meike, die selbst Veteranin der Tanzgarde ist, geleitet. Hierbei wird viel zusammen gelacht, geschwitzt und das ein oder andere Paar Schuhe durchgetanzt.",
            "Die Tanzgarde der KG Neustadtfunken besteht aus den schönsten und talentiertesten Mädchen der Opladener Neustadt und umfasst aktuell 11 Tänzerinnen im Alter von 16 bis 29 Jahren.",
            "Ob auf oder neben der Bühne, sie sind ein starkes Team, das stets zusammenhält und gerne gemeinsam seine Zeit verbringt.",
            " Wenn die Straßen endlich wieder bunt werden und aus allen Ecken kölsche Melodien erklingen, gibt es für die Mädels der Tanzgarde kein Halten mehr.",
            "Das ganze Jahr fiebern sie auf diese schöne Zeit hin, in der sie auf den jecken Bühnen ihre Tänze präsentieren und das Publikum verzaubern können.",
            "Besonders Weiberfastnacht lassen sie es sich nicht nehmen, ihre Uniform mit liebevoll geplanten Details, „aufzupäppeln“ und damit nicht nur das Publikum, sondern auch unser Traditionscorps zu überraschen.",
            " Auch wenn ihr Frühstück an dem ein oder anderen Morgen mal mit Magnesiumtabletten kombiniert werden muss oder der Kopf vom Haare flechten schmerzt, zeigen sie, dass der Karneval so viel mehr als nur ein Hobby für sie ist.",
            "Denn sobald sie gemeinsam singen, feiern und in ihren Uniformen im rut und wießen Glanz erstrahlen, sind alle Sorgen vergessen.",
            " ",
            "Daher dun sie et immer widder, Johr für Johr."
        ]);
    },
    getPawnsContent: async function () {
        return Promise.resolve([
            "„Rut un wiess, wie lieb ich dich“(Bläck Fööss), dieses Motto haben bereits schon unsere ganz Kleinen, unsere Pänz vom Rosenhügel, der Neustadtfunken ganzer Stolz, im Blut.",
            "",
            "Mit insgesamt 32 Kindern, 5 Jungs und 27 Mädchen sind sie eine der größten Kinder- und Jugendtanzcorps in Leverkusen. ",
            "Um vom 11.11 bis Aschermittwoch die Herzen aller Zuschauer zu verzaubern, zeigen sie beim wöchentlichen Training vollen Eifer.",
            "Für unsere Pänz gibt es kein schöneres Gefühl, als von der Schule zu kommen, die roten Tanzstiefel zu schnüren, die Zöpfe zu flechten bzw. das Haar zu bändigen und die geliebte Uniform anzuziehen, um zu den Auftritten zu fahren und darzubieten, was man das ganze Jahr gelernt hat. ",
            "",
            "Unsere „Kleinen“, im Alter zwischen 4 und 11 Jahren, trainieren jeden Mittwoch von 17:00- 18:00 Uhr in unserem heimischen Funkenkeller. Und leben mit großer Freude ihre Liebe zum Karneval in ihren Tänzen aus. Begleitet werden sie hierbei von Petra Hebbel, die selber schon seit Jahrzehnten ein fester Bestandteil der Neustadtfunken ist. Unterstützt wird sie dieses Jahr zusätzlich noch von Loredana und Sarah Ziegler.",
            "",
            "Unsere „großen“ Pänz  trainieren ebenfalls mittwochs  von 18:00-19:30. Trainiert werden sie von Anne Berghöfer-Weis, die selbst ihre Anfänge bei den Pänz vom Rosenhügel hatte. In der Jugend trainiert alles ab 11 Jahren bis zum Eintritt in das Traditionscorps oder in die Tanzgarde.",
            "",
            "Seit nun 25 Jahren sind die Pänz ein fester Bestandteil der Neustadtfunken, und für niemanden mehr wegzudenken.  Und wir hoffen natürlich, dass nochmal mindestens  11 x 25 Jahre dazu kommen.",
            "In diesem Jahr wollen wir gemeinsam mit allen Jecken und unseren Pänz:  „Tanzen unterm Tisch solange wie geht, und singen dabei bis es morgen wird!“ ",
            "In diesem Sinne auf unsere Pänz vom Rosenhügel, von Hätze, ein dreifaches Alaaf!"
        ]);
    },
    getPawnsPairContent: async function () {
        return Promise.resolve([
            "Auch in dieser Session haben wir das große Glück ein Kinderprinzenpaar gefunden zu haben das unseren Verein repräsentieren wird. ",
            "",
            "Mit Niklas Meermann und Lina Müller werden zwei „ alte Hasen“ das Amt bekleiden. ",
            "",
            "Niklas, 15 Jahre alt, tanzt seit 9 Jahren bei unseren Pänz vom Rosenhügel sowie sein Bruder Leon, der zudem die Standarte trägt. Seine Schwester Alina ist unsere Marie und Mama Ela unterstützt den Vorstand und die Fußgruppe der Gesellschaft. ",
            "Und wenn es mal brennen sollte, sind wir bei ihm auch in guten Händen: Seit vielen Jahren ist er bei der Jugendfeuerwehr aktiv. ",
            "",
            "Lina, bald 14 Jahre alt, tanzt auch schon seit 7 Jahren bei den Pänz und kennt sich auf der Bühne aus. Neben Klavier spielen und reiten, ist das tanzen eines ihrer Hobbys. Dieses Jahr wird sie zwar unsere Prinzessin aber auf das tanzen verzichtet Sie nicht ganz. Lasst Euch überraschen!",
        ]);
    },
    getPairContent: async function () {
        return Promise.resolve([
            "Die Beiden gehören zu den Neustadtfunken, wie die Wupper zu Opladen: Unser Tanzpaar.  Sie sind das Symbole für die rot-weiße Lebensfreude und der ganze Stolz der Gesellschaft.",
            "Unsere Marie Alina (Meermann) wird von ihrem Tanzoffizier Flo (Hessenbruch) über die Bretter die die Welt bedeuten getragen. Sie schaffen es noch den größten Karnevalsmuffel ein Lächeln ins Gesicht zu zaubern und ihn zum Mitklatschen zu animieren. ",
            "Sie tragen nicht nur die Farben, sondern sie leben sie. Sie trainieren das ganze Jahr hart um in der 5. Jahreszeit den Menschen einen tollen Moment zu schenken. Auch außerhalb der Trainingshalle engagieren sie sich stets für die Gesellschaft, ihr Tag muss mehr als 24 Stunden haben, sonst würden sie nicht all` dies schaffen.",
            "Alina arbeitet als Pflegefachfrau in der Notaufnahme im Klinikum. Flo hingegen verbringt seine „Trainingsfreie“ Zeit entweder mit seiner Frau und seinem Sohn oder bei der EVL, wo er als Hausmeister tätig ist. ",
            "Ihr neuer Trainer, Thomas Hoffmann, hatte zu Beginn der Trainingszeit Großes mit den Beiden vor. Er erkannte sofort was in ihnen steckt: Harmonie, Ehrgeiz und vor allem jede Menge „Bock die Bühnen zu rocken“.  Wir dürfen gespannt sein auf ihren neuen Paartanz und freuen uns riesig auf eine tolle Session mit euch."
        ]);
    },
    getPedestriansContent: async function () {
        return Promise.resolve([
            "Die Fußgruppe der KG Neustadtfunken ist ein weiterer Zweig der Gesellschaft, der es sich zur Aufgabe gemacht hat, den Rosenmontag noch etwas bunter zu machen. Jedes Jahr aufs Neue lassen sie gemeinsam ihrer Kreativität freien Raum um sich Kostüme für den Zug zu überlegen und diese dann selbst zu nähen. Niemand bestimmt etwas, sondern alle Ideen sind willkommen und am Ende entscheiden alle gemeinsam. Bereits als Chinesen, Hofdamen, Gaukler oder Postjungen machten sie den Rosenmontagszug unsicher und überschütteten die Jecken mit einer Flut aus Kamellen.",
            "Die Fußgruppe setzt sich aus einer bunten Mischung aus ehemaligen Tänzern, Pänzeltern, Freunden der Gesellschaft, Senatoren oder Geschwistern zusammen. Eben diese vielseitige Konstellation macht die Fußgruppe so besonders und einzigartig.",
            "Wir sind unendlich stolz auf diese tolle Truppe, die uns und vor allem die Jecken am Straßenrand jedes Jahr wieder verzaubern. Wir als Gesellschaft wollen diesen „Zweig“ nie wieder missen und danken allen für ihre Arbeit, Zeit, Mühe und Liebe!",
            "Wer auch Lust hat, Teil dieser tollen Gruppe zu werden, kann sich jederzeit bei uns melden! Wir freuen uns über jeden Jecken, der mit uns zusammen den Rosenmontagszug unsicher macht."
        ]);
    }
};

export default fakeContentService;