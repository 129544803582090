import React from 'react';

const VisitcardComponent = (props) => {
    const { title, profiles } = props;
    return (
        <blockquote className="kgnsf-blockquote">
            {title && <h5>{title}</h5>}
            {profiles && profiles.map(profile => {
                return (
                    <div key={profile.name}>
                        {profile.name && <div>{profile.name}</div>}
                        {profile.phone && <div className='small'>Mobil: {profile.phone}</div>}
                        {profile.email && <div className='small'>Email: <a href={"mailto:" + profile.email}>{profile.email}</a></div>}
                    </div>
                )
            })}
        </blockquote>
    );
}

export default VisitcardComponent