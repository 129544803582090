import React from "react";
import fakeProfilesService from "../services/fakeProfilesService";

class FooterComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contact: [],
            mediaCredits: []
        };
    }

    async componentDidMount() {
        const contact = await fakeProfilesService.getProfiles({
            filter: profile => {
                return profile.firstname === "Kontakt"
            }
        });

        const mediaCredits = await fakeProfilesService.getProfiles({
            filter: profile => {
                return profile.credits === "x"
            },
            orderBy: (a, b) => a.sort - b.sort || a.lastname.localeCompare(b.lastname)
        });

        this.setState({ contact, mediaCredits });
    }

    render() {
        const { contact, mediaCredits } = this.state;

        return (
            <div className="kgnsf-footer bottom-0">
                <div className="container-fluid row">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        {contact && contact.map(contact => {
                            return (
                                <div key={contact.name}>
                                    <h2>{contact.firstname}</h2>
                                    <p>{contact.name}</p>
                                    <p>{contact.address}</p>
                                    <p>{contact.postalcode + " " + contact.city}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        {contact && contact.map(contact => {
                            return (
                                <div key={contact.name}>
                                    <h2>{contact.lastname}</h2>
                                    <a className="link-light" href={"mailto:" + contact.email}>{contact.email}</a>
                                </div>
                            )
                        })}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <h2>Bildquellen</h2>
                        {mediaCredits && mediaCredits.map(profile => {
                            return (
                                <p key={profile.name}>{profile.name} <span><a className="link-light" href={"https://" + profile.homepage} target="_blank" rel="noreferrer">{profile.homepage}</a></span></p>
                            )
                        })}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <h2>Facebook</h2>
                        <a className="link-light" href="https://de-de.facebook.com/KG-Neustadtfunken-Opladen-1928-eV-120445274729924/?ref=page_internal" target="_blank" rel="noreferrer">KG Neustadtfunken Opladen 1928 e.V.</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default FooterComponent;