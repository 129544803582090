const NotFoundComponent = () => {
    return (
        <div className="container row ">
            <img alt="" className="col-md-2 col-lg-2 img-fluid" src={"/images/NeustadtFunkeTrans.png"} />
            <blockquote className="col kgnsf-blockquote">
                <h3>404</h3>
                <h4>page not found...</h4>
            </blockquote>
        </div>
    );
};

export default NotFoundComponent;