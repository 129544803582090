import React from "react";

const ContentBlockquote = ({ content }) => {
    return (
        <blockquote className="kgnsf-blockquote">
            {content && content.map((p, index) => {
                return (
                    <p key={"block-padding-" + index}>{p}</p>
                );
            })}
        </blockquote>
    );
};

export default ContentBlockquote;