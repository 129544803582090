import React from "react";
import fakeContentService from "../services/fakeContentService";
import fakeProfilesService from "../services/fakeProfilesService";
import ContentBlockquote from "./StateLessComponents/ContentBlockquote";
import ContentImage from "./StateLessComponents/ContentImage";
import VisitcardComponent from "./VisitcardComponent";

class PedastriansComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bookingPartner: []
        };
    }

    async componentDidMount() {
        const bookingPartner = await fakeProfilesService.getProfiles({ filter: p => { return p.bookPed === "x" } });
        const content = await fakeContentService.getPedestriansContent();

        this.setState({
            bookingPartner,
            content
        })
    }

    render() {
        const { bookingPartner, content } = this.state;

        return (
            <div>
                <h1 className="kgnsf-header">Unsere Fußgruppe</h1>

                <ContentImage image={"/images/neustadtfunken/fussgruppe/FG20.jpeg"} label={"Unsere Fußgruppe 2020/2021"} />

                <ContentBlockquote content={content} />

                <div className='row justify-content-end'>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        {bookingPartner && <VisitcardComponent title="Bei Fragen / Interesse" profiles={bookingPartner}></VisitcardComponent>}
                    </div>
                </div>
            </div >
        )
    }
}

export default PedastriansComponent