import React from "react";
import fakeContentService from "../services/fakeContentService";
import fakeProfilesService from "../services/fakeProfilesService";
import ContentImageBlockquote from "./StateLessComponents/ContentImageBlockquote";
import VisitcardComponent from "./VisitcardComponent";

class PawnspairComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            princePairCaretaker: [],
            bookingPartner: []
        };
    }

    async componentDidMount() {
        const princePairCaretaker = await fakeProfilesService.getProfiles({ filter: p => { return p.betrKPP === "x" } });
        const bookingPartner = await fakeProfilesService.getProfiles({ filter: p => { return p.bookP === "x" } });
        const contentPair = await fakeContentService.getPawnsPairContent();

        this.setState({
            princePairCaretaker,
            bookingPartner,
            contentPair
        })
    }

    render() {
        const { princePairCaretaker, bookingPartner, contentPair } = this.state;

        return (
            <div>
                <h1 className="kgnsf-header">Unser Kinderprinzenpaar</h1>

                <ContentImageBlockquote imageSrc={"NSF Fototermin 2023/Gruppenbilder/kinderprinzenpaar.jpeg"} imageLabel={"Prinzessin Lina I. und Prinz Niklas I."} content={contentPair} />

                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        {princePairCaretaker && <VisitcardComponent title="Betreuung: Kinderprinzenpaar" profiles={princePairCaretaker}></VisitcardComponent>}
                    </div>
                    <div className="col">
                        {bookingPartner && <VisitcardComponent title="Bei Fragen / Buchung" profiles={bookingPartner}></VisitcardComponent>}
                    </div>
                </div>
            </div>
        )
    }
}

export default PawnspairComponent