import React from "react";

const ContentImage = ({ image, label }) => {
    return (
        <React.Fragment>
            {image && <img alt="" className="img-fluid kgnsf-img" src={image} />}
            {label && <p className="kgnsf-img-title">{label}</p>}
        </React.Fragment>
    );
};

export default ContentImage;