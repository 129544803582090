import React from "react";
import fakeContentService from "../services/fakeContentService";
import fakeProfilesService from "../services/fakeProfilesService";
import ContentImage from "./StateLessComponents/ContentImage";
import ContentImageBlockquote from "./StateLessComponents/ContentImageBlockquote";
import VisitcardComponent from "./VisitcardComponent";

class GardeComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            trainersGarde: [],
            commanderGarde: [],
            bookingPartner: []
        };
    }

    async componentDidMount() {
        const trainersGarde = await fakeProfilesService.getProfiles({ filter: p => { return p.trTG === "x" }, orderBy: (a, b) => a.lastname.localeCompare(b.lastname) });
        const commanderGarde = await fakeProfilesService.getProfiles({ filter: p => { return p.cTG === "x" }, orderBy: (a, b) => a.lastname.localeCompare(b.lastname) });
        const bookingPartner = await fakeProfilesService.getProfiles({ filter: p => { return p.bookTG === "x" } });
        const content = await fakeContentService.getGardeContent();

        trainersGarde?.forEach(profile => {
            profile.phone = null;
            profile.email = null;
        });

        commanderGarde?.forEach(profile => {
            profile.phone = null;
            profile.email = null;
        });

        this.setState({
            trainersGarde,
            commanderGarde,
            bookingPartner,
            content
        })
    }

    render() {
        const { trainersGarde, commanderGarde, bookingPartner, content } = this.state;

        return (
            <div>
                <h1 className="kgnsf-header">Unsere Tanzgarde</h1>

                <ContentImage image={"NSF Fototermin 2023/Gruppenbilder/Tanzgarde 2.jpg"} label={"Unsere Tanzgarde 2023 / 2024"} />

                <ContentImageBlockquote imageSrc={commanderGarde[0]?.picture} imageLabel={commanderGarde[0]?.name} content={content} />

                <div className="row">
                    <div className="col">
                        {trainersGarde && <VisitcardComponent title="Trainerin: Tanzgarde" profiles={trainersGarde}></VisitcardComponent>}
                    </div>
                    <div className="col">
                        {commanderGarde && <VisitcardComponent title="Kommandantin: Tanzgarde" profiles={commanderGarde}></VisitcardComponent>}
                    </div>
                    <div className="col">
                        {bookingPartner && <VisitcardComponent title="Bei Fragen / Buchung" profiles={bookingPartner}></VisitcardComponent>}
                    </div>
                </div>
            </div >
        )
    }
}

export default GardeComponent