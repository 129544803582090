import React from "react";

const HistoryComponent = () => {
    return (
        <div>
            <h1 className="kgnsf-header">Historischer Hintergrund</h1>

            <div className="row">
                <div className="col">
                    <blockquote className="kgnsf-blockquote">
                        <h3>Ein erstes Mal...</h3>
                        <p>Seit 1928 sind die rot-weißen aus der Neustadt eine nicht mehr wegzudenkende Größe im Opladener Karneval. Wie bei allem gab es auch in unserer Gesellschaft ein 'erstes Mal'.</p>
                        <p>Wie alles begann...</p>
                        <p>Dieses erste Mal ereignete sich am Rosenmontag des Jahres 1927. Bereits in diesem Jahr, ein Jahr vor der offiziellen Gründung der Gesellschaft, bewiesen einige Bürger der Neustadt, dass ihnen der Schalk im Nacken saß und in ihren Adern karnevalistisches Blut schäumte.</p>
                        <p>Mit Karren aller Art zogen sie die Kölner Straße hinunter, um die Altstadtfunken mit einem Besuch zu 'überraschen'. Die kostbare Last in den Karren bestand aus Bergen von Abfall, Kehricht, Müll, leeren Schnapsflaschen und rappelndem 'Gebrässels'. Jupp Dinnendahl schob gar eine Jauchetonne (ohne Inhalt!) vor sich her, die deutlich nach 'Echt Kölnischem Wasser' duftete.......</p>
                        <p>In der Nähe der Gaststätte 'Zum Stippenhüschen' im ahlen Dorp angekommen, versuchten die Altstadtfunken, unter ihnen der originelle Dorfschutzmann 'Mottenbüggel' und 'et Stiefens Agnies' den Neustädtern den Weg zu verwehren, indem sie ein Strohseil über die Straße spannten. Erst nach langen Verhandlungen, die feucht-fröhlicher Art waren, und wobei der misstrauische Dorfschutzmann erst mal eine Nase voll Duft aus der Jauchetonne riskierte, wurde die somit schwer erkämpfte Durchfahrt endlich freigegeben. Nun ging es mit lautem Hallo hinein ins Stippenhüschen, wo mit einem höchstvergnügten Umtrunk die heiß gelaufenen Karrenräder 'geölt' wurden.</p>
                    </blockquote>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <blockquote className="kgnsf-blockquote">
                        <h3>Die Gründung der Gesellschaft</h3>
                        <p>Dann endlich war es soweit. Wir schreiben das Jahr 1928. Bei einem Dämmerschoppen in der neustädtischen Schänke des Gastwirtes Theodor Jünnemann fand eine höchst denkwürdige 'Thekenplauderei' statt. Irgendwie lag an jenem Abend die bedeutungsvolle Gründung unserer Karnevalsgesellschaft zum Greifen nahe in der Luft. Man redete viel und man erhitzte sich bei Bier und Tabaksqualm mehr als nötig, bis endlich der stramm gewachsene Steuerberater Karl Moll aus der Augustastraße im Scherz als Präsident für die vielleicht zu gründende Gesellschaft vorgeschlagen wurde. Allein, als es mit der Gründung ernst wurde, kniff er leider, obschon er durch seine bürgermeisterliche Gestalt für das 'schwere'' Amt des Präsidenten geradezu geschaffen war.</p>
                        <p>Statt seiner meldete sich am nächsten Tag -unaufgefordert- der Elektrikermeister Heinrich Keusgen von der Kölner Straße, um zusammen mit einem kleinen Kreis Gleichgesinnter, muss heißen verdötschter Jecken, am 13.Februar 1928 am runden Tisch der Gaststätte Jünnemann, die Gesellschaft zur glücklichen Gründung zu bringen.</p>
                        <p>Über diese hoch offizielle Gründung wurde eine künstlerisch wertvolle Urkunde gefertigt, die heute als besonderes Prunkstück im Funkenkeller unserer Gesellschaft zu bewundern ist.</p>
                    </blockquote>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <img alt="" className="img-fluid" src={"/images/neustadtfunken/urkunde.png"} />
                </div>
            </div>

            <br />
        </div>
    );
}

export default HistoryComponent;