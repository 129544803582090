import React from "react";
import fakeNewsService from "../services/fakeNewsService";

class NewsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: []
        };
    }

    async componentDidMount() {
        const news = await fakeNewsService.getNews();

        if (news) this.setState({ news });
    }

    render() {
        const { news } = this.state;

        return (
            <blockquote className="kgnsf-blockquote">
                <h2 className="kgnsf-header center">Aktuelles</h2>
                <ul className="list-group">
                    {news?.map((article, index) => {
                        return (
                            <li className="list-group-item" key={index}>
                                <h4>{article.title}</h4>
                                <div className="row">
                                    {article.image && <div className="col-md-4 col-lg-4 col-sm-12">
                                        <img alt="" className="img-fluid" src={article.image} />
                                    </div>}
                                    <div className="col kgnsf-content">
                                        {article.content}
                                    </div>
                                </div>
                                {article.files && article.files.map((file, index) => {
                                    return (<a className="btn btn-danger end" href={file.src} key={file.title + index} target="_blank" rel="noreferrer">
                                        <i className="bi bi-download"></i> {file.title}
                                    </a>)
                                })}
                            </li>
                        )
                    })}
                </ul>
            </blockquote>
        );
    }
}

export default NewsComponent;