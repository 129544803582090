import React from "react";
import fakeProfilesService from "../services/fakeProfilesService";

class ImprintComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contact: [],
            responsibles: []
        };
    }

    async componentDidMount() {
        const contact = await fakeProfilesService.getProfiles({
            filter: profile => {
                return profile.firstname === "Kontakt"
            }
        });

        const responsibles = await fakeProfilesService.getProfiles({
            filter: profile => {
                return profile.imprint === "x"
            },
            orderBy: (a, b) => {
                return a.sort - b.sort || a.lastname.localeCompare(b.lastname)
            }
        });

        this.setState({ contact, responsibles });
    }

    render() {
        const { contact, responsibles } = this.state;

        return (
            <div>
                <h1 className="kgnsf-header">Impressum</h1>

                {contact && contact.map(contact => {
                    return (
                        <blockquote key={contact.name} className="kgnsf-blockquote">
                            <h3>Angaben gemäß § 5 TMG:</h3>
                            <div>{contact.name}</div>
                            <div>{contact.address}</div>
                            <div>{contact.postalcode + " " + contact.city}</div>
                        </blockquote>
                    )
                })}

                <blockquote className="kgnsf-blockquote">
                    <h3>Vertreten durch:</h3>
                    {responsibles && responsibles.map(profile => {
                        return (
                            <div key={profile.name}>
                                {profile.rank + ": " + profile.name}
                                {profile.email && <span>, Email: <a href={"mailto:" + profile.email}>{profile.email}</a></span>}
                            </div>
                        )
                    })}
                </blockquote>

                <blockquote className="kgnsf-blockquote">
                    <h3>Registereintrag:</h3>
                    <div>Eintragung im Vereinsregister</div>
                    <div>Registergericht: Amtsgericht Köln</div>
                    <div>Registernummer: VR 400526</div>
                </blockquote>

                <blockquote className="kgnsf-blockquote">
                    <h3>Datenschutz</h3>
                    <div>
                        Wir fühlen uns dem Schutz der Privat- und Persönlichkeitssphäre der Besucher dieser Webseite verpflichtet. Im Folgenden können Sie nachlesen, wie personenbezogene Daten, die Sie uns während des Besuchs dieser Webseite übermitteln, behandelt werden. Grundsätzlich können Sie auf dieser Webseite navigieren, ohne personenbezogene Daten anzugeben. Beim Ausfüllen der Maske zur Kontaktaufnahme können Sie uns jedoch Daten übermitteln, die Sie persönlich identifizieren. Diese Daten behandeln wir gemäß den geltenden gesetzlichen Bestimmungen zum Datenschutz. Wir nutzen sie nur, um mit Ihnen entsprechend Ihrer Anfrage in Kontakt zu treten und Ihnen gegebenenfalls gewünschte Informationen zukommen zu lassen. Eine Weitergabe an Dritte erfolgt nicht. Personenbezogene Daten, die Sie uns übermitteln, werden nur so lange gespeichert, wie sie benötigt werden, um die Zwecke zu erfüllen, zu denen diese Daten übermittelt wurden oder solange dies von Gesetzes wegen vorgeschrieben ist. Sollten Sie weitere Fragen zum Datenschutz haben, nehmen Sie bitte mit uns Kontakt auf. Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie E-Mailadressen durch Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht gestattet. Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails bei Verstößen gegen dieses Verbot behalten wir uns ausdrücklich vor.
                    </div>
                </blockquote>

                <blockquote className="kgnsf-blockquote">
                    <h3>Haftungsausschluss</h3>
                    <em>Haftung für Inhalte</em>
                    <p>
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    </p>

                    <em>Haftung für Links</em>
                    <p>
                        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                    </p>

                    <em>Urheberrecht</em>
                    <p>
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                    </p>
                </blockquote>

                <div className="row"></div>
            </div>
        )
    }
}

export default ImprintComponent;